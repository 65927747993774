<!-- [style.opacity]="isOpened() ? 1 : 0" -->
<div
  (mouseleave)="onMouseLeave()"
  class="tooltip at-bg-white at-px-lg at-py-2xl at-radius-md at-shadow-lg"
  #tooltip>
  <div [class]="'tooltip-arrow tooltip-arrow-' + placement()"></div>
  <div class="at-flex at-justify-between at-align-start">
    <p class="at-text-neutral-900 at-fw-semibold at-text-xs">{{ title() }}</p>

    @if (hasCloseIcon()) {
    <button
      class="at-bg-transparent at-border at-border-neutral-300 at-p-sm at-radius-sm at-flex at-cursor-pointer"
      (click)="closeTooltip()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none">
        <path
          d="M2.5 2.5L9.49953 9.49953"
          stroke="#111827"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M2.50047 9.49953L9.5 2.5"
          stroke="#111827"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </button>
    }
  </div>
  <ng-content></ng-content>
  @if (description()) {
  <p class="description at-text-neutral-500 at-text-xs at-mb-md at-mt-x-sm">
    {{ description() }}
  </p>
  } @if (hasActionButton()) {
  <div class="at-flex at-justify-end">
    <button
      (click)="makeActionWhenClickActionButton()"
      class="action at-bg-white at-text-neutral-700 at-text-sm at-fw-medium at-border at-border-neutral-300 at-radius-sm">
      {{ actionButtonLabel() }}
    </button>
  </div>
  }
</div>
