import {
  AfterViewInit,
  Component,
  ElementRef,
  input,
  output,
  viewChild,
} from '@angular/core';

@Component({
  selector: 'lib-tooltip',
  standalone: true,
  imports: [],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
})
export class TooltipComponent implements AfterViewInit {
  isOpened = input<boolean>(false);

  hasCloseIcon = input<boolean>(true);
  hasActionButton = input<boolean>(false);

  title = input.required<string>();
  description = input<string>();

  top = 0;
  left = 0;

  actionButtonLabel = input<string>();
  action = output();
  close = output();

  placement = input<TooltipPlacement>('right');

  onMouseLeaveToolTip = output();

  tooltipTemplate = viewChild<ElementRef>('tooltip');
  templateHeight!: number;
  templateWidth!: number;

  ngAfterViewInit() {
    this.templateHeight = this.tooltipTemplate()?.nativeElement.offsetHeight;
    this.templateWidth = this.tooltipTemplate()?.nativeElement.offsetWidth;
  }

  makeActionWhenClickActionButton() {
    this.action.emit();
  }

  onMouseLeave() {
    this.onMouseLeaveToolTip.emit();
  }

  closeTooltip() {
    this.close.emit();
  }
}

export type TooltipPlacement = 'top' | 'left' | 'bottom' | 'right';
